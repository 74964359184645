import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: () => {
      return "/KnowledgeSkillStrategy";
    },
  },
  {
    path: "/KnowledgeSkillStrategy",
    name: "KnowledgeSkillStrategy",
    component: () => import("../views/RadialTreeView.vue"),
  },
  {
    path: "/KnowledgeExplorer",
    name: "KnowledgeExplorer",
    component: () => import("../views/ScatterView.vue"),
  },
  // {
  //   path: "/mydetail",
  //   name: "mydetail",
  //   component: () => import("../views/MydetailPage.vue"),
  // },
  // {
  //   path: "/explorer",
  //   name: "explorer",
  //   component: () => import("../views/ExplorerPage.vue"),
  //   children: [
  //     {
  //       path: "skill",
  //       name: "skill",
  //       component: () => import("../views/RadialTreeView.vue"),
  //     },
  //     {
  //       path: "dataset",
  //       name: "dataset",
  //       component: () => import("../views/ScatterView.vue"),
  //     },
  //   ],
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
