import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
// import dataMock from "../assets/mock/format.json";
import G6 from "@antv/g6";

Vue.use(Vuex);

// const domain = process.env.VUE_APP_API_URL;
const domain = process.env.VUE_APP_APIURL;

export default new Vuex.Store({
  state: {
    select: "",
    selectedItem: {},
    treeFilter: {},
    graphData: {},
    job_id: null,
    job_relate: [],
    facultyOptions: [],
    tsicOptions: [],
    jobDescription: {},
    loadData: false,
  },
  mutations: {
    SET_SELECT(state, payload) {
      state.select = payload;
    },
    SET_ITEM(state, payload) {
      state.selectedItem = payload;
    },
    SET_LOADDATA(state, payload) {
      state.loadData = payload;
    },
    SET_FILTERVALUE(state, payload) {
      state.treeFilter = payload;
    },
    SET_ANALYZETSIC(state, payload) {
      console.log("payload", payload);
      let node = [];
      let link = [];

      if (payload.type === "en" && payload.data.length > 0) {
        node.push({
          id: "0",
          label: "Company \nProfile",
          full_label: "Company Profile",
          size: 120,
          labelCfg: {
            position: "center",
            offset: 5,
            style: {
              fill: "#fff",
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          style: {
            fill: "#2C5A74",
          },
        });
      } else if (payload.type === "app" && payload.data.length > 0) {
        node.push({
          id: "0",
          label: "Applicant \nProfile",
          full_label: "Applicant Profile",
          size: 120,
          labelCfg: {
            position: "center",
            offset: 5,
            style: {
              fill: "#fff",
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          style: {
            fill: "#2C5A74",
          },
        });
      }

      if (payload?.data) {
        const fittingString = (str, maxWidth, fontSize) => {
          const ellipsis = "...";
          const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
          let currentWidth = 0;
          let res = str;
          const pattern = new RegExp("[\u4E00-\u9FA5]+"); // distinguish the Chinese charactors and letters
          str.split("").forEach((letter, i) => {
            if (currentWidth > maxWidth - ellipsisLength) return;
            if (pattern.test(letter)) {
              // Chinese charactors
              currentWidth += fontSize;
            } else {
              // get the width of single letter according to the fontSize
              currentWidth += G6.Util.getLetterWidth(letter, fontSize);
            }
            if (currentWidth > maxWidth - ellipsisLength) {
              res = `${str.substr(0, i)}${ellipsis}`;
            }
          });
          return res;
        };

        const globalFontSize = 16;
        payload.data.map((i) => {
          node.push({
            id: i.name,
            label: i.name,
            parent_id: "0",
            full_label: i.name,
            size: 50,
          });
          link.push({
            source: "0",
            target: i.name,
          });
          i.job_list.map((job) => {
            node.push({
              id: job.id.toString(),
              label: fittingString(job.attribute.name, 120, globalFontSize),
              full_label: job.attribute.name,
              parent_id: i.name,
              anchorPoints: [
                [0, 0.5],
                [1, 0.5],
              ],
            });
            link.push({
              source: i.name,
              target: job.id.toString(),
            });
          });
        });
      }

      let newData = {
        nodes: node,
        links: link,
      };
      console.log("newdA", newData);
      state.graphData = newData;
    },
    SET_JOB(state, payload) {
      state.job_id = parseInt(payload);
    },
    SET_RELATEJOB(state, payload) {
      let formatData = {
        name: payload.name,
        description: payload.description,
      };
      state.job_relate = formatData;
    },
    GET_FACULTY(state, payload) {
      state.facultyOptions = payload.map((i) => {
        return {
          id: i.id,
          isced_id: i.iscd,
          text: i.name,
        };
      });
    },
    GET_TSIC(state, payload) {
      state.tsicOptions = payload.map((i) => {
        return {
          id: i.id,
          tsic_id: i.tsic_id,
          isic: i.isic,
          text: i.name,
        };
      });
    },
    SET_JOBDESCRIPTION(state, payload) {
      state.jobDescription = payload;
    },
  },
  actions: {
    setSelect({ commit }, payload) {
      commit("SET_SELECT", payload);
    },
    setItem({ commit }, payload) {
      commit("SET_ITEM", payload);
    },
    setFIlter({ commit }, payload) {
      commit("SET_FILTERVALUE", payload);
    },
    analyzeData({ commit }, payload) {
      commit("SET_ANALYZETSIC", {});
      commit("SET_LOADDATA", true);
      switch (payload.type) {
        case "en":
          axios
            .post(`${domain}/strategy/entrepreneur/`, payload.payload)
            .then((res) => {
              console.log("entrepreneur res :", res.data);

              let responseData = {
                type: payload.type,
                data: res.data,
              };
              commit("SET_ANALYZETSIC", responseData);
              if (res.status === 200) {
                commit("SET_LOADDATA", false);
              }
            });
          break;
        case "app":
          axios
            .post(`${domain}/strategy/applicant/`, payload.payload)
            .then((res) => {
              console.log("applicant res :", res.data);

              let responseData = {
                type: payload.type,
                data: res.data,
              };
              commit("SET_ANALYZETSIC", responseData);
              if (res.status === 200) {
                commit("SET_LOADDATA", false);
              }
            });
          break;
      }
    },
    selectJob({ commit }, payload) {
      commit("SET_JOB", payload);
      axios
        .get(`${domain}/jobpost/?job_position=${payload}`)
        .then((res) => commit("SET_RELATEJOB", res.data));
    },
    getFaculty({ commit }) {
      axios
        .get(`${domain}/faculty/`)
        .then((res) => commit("GET_FACULTY", res.data));
    },
    getTSIC({ commit }) {
      axios.get(`${domain}/tsic/`).then((res) => commit("GET_TSIC", res.data));
    },
    getJobDescription({ commit }, payload) {
      axios
        .get(`${domain}/node/${payload}/`)
        .then((res) => commit("SET_JOBDESCRIPTION", res.data));
    },
  },
  modules: {},
});
